<template>
  <b-card>
    <g-form @submit="save">
      <b-row>
        <!-- code -->
        <b-col md="3">
          <g-field
            id="code"
            label-text="code"
            :value.sync="selectedItem.code"
            type="number"
            name="code"
          />
        </b-col>

        <!-- transactionDate -->
        <b-col md="3">
          <g-picker
            :value.sync="selectedItem.transactionDate"
            label-text="date"
            name="date"
          />
        </b-col>

        <!-- employee -->
        <b-col md="3">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.employeeId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="employee"
              field="select"
              name="employee"
              rules="required"
              :options="employees"
              :label="isRight ? 'arabicName' : 'englishName'"
              @change="
                (v) => {
                  employeeObject = v;
                  this.selectedItem.salaryItemId = '';
                  this.selectedItem.salaryItem = [];
                  this.selectedItem.nature = '';
                  this.selectedItem.value = 0;
                  this.selectedItem.total = 0;
                  this.selectedItem.fromMonth = 0;
                  this.selectedItem.toMonth = 0;
                  redeclareMonth();
                }
              "
            />
          </b-form-group>
        </b-col>

        <!-- types benefits or deduction -->
        <b-col md="3">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.itemType"
              :disabled="selectedItem.employeeId <= 0"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="benefitsAndDeductionsTypes"
              rules="required"
              field="select"
              name="benefitsAndDeductionsTypes"
              :options="salaryItemTypes"
              label="arabicName"
              @change="
                (v) => {
                  this.selectedItem.salaryItemId = '';
                  this.selectedItem.salaryItem = [];
                  this.selectedItem.nature = '';
                  this.selectedItem.value = 0;
                  this.selectedItem.total = 0;
                }
              "
            />
          </b-form-group>
        </b-col>

        <!-- salary item -->
        <b-col
          v-if="selectedItem.itemType"
          md="3"
        >
          <b-form-group>
            <g-field
              v-if="selectedItem.itemType === 'earning'"
              :value.sync="selectedItem.salaryItemId"
              rules="required"
              :dir="isRight ? 'rtl' : 'ltr'"
              field="select"
              name="salaryItems"
              :options="salaryItems"
              label-text="benfits"
              :label="isRight ? 'arabicName' : 'englishName'"
              :filter-fn="(item) => item.itemType === selectedItem.itemType"
              @change="
                (v) => {
                  updateNature(v);
                  benefitAndDeductionObject = v;
                  this.selectedItem.value = 0;
                  this.selectedItem.total = 0;
                }
              "
            />
            <g-field
              v-if="selectedItem.itemType === 'deduction'"
              :value.sync="selectedItem.salaryItemId"
              :dir="isRight ? 'rtl' : 'ltr'"
              rules="required"
              field="select"
              name="salaryItems"
              :options="salaryItems"
              :filter-fn="(item) => item.itemType === selectedItem.itemType"
              :label="isRight ? 'arabicName' : 'englishName'"
              label-text="deductions"
              @change="
                (v) => {
                  updateNature(v);
                  benefitAndDeductionObject = v;
                  this.selectedItem.value = 0;
                  this.selectedItem.total = 0;
                }
              "
            />
          </b-form-group>
        </b-col>

        <!-- nature -->
        <b-col md="3">
          <b-form-group>
            <g-field
              field="select"
              label-text="nature"
              name="nature"
              label="arabicName"
              :dir="isRight ? 'rtl' : 'ltr'"
              :options="salaryItemNatures"
              :value.sync="selectedItem.nature"
              disabled
            />
          </b-form-group>
        </b-col>

        <!-- from year -->
        <b-col md="3">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.fromYear"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="fromYear"
              field="select"
              name="fromYear"
              :options="years"
              label="arabicName"
              @change="
                (v) => {
                  selectedItem.fromMonth = 0;
                  updateToYear(v);
                  redeclareMonth();
                }"
            />
          </b-form-group>
        </b-col>
        <!-- from month -->
        <b-col md="3">
          <b-form-group>
            <g-field
            :disabled="!selectedItem.employeeId"
              :value.sync="selectedItem.fromMonth"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="fromMonth"
              field="select"
              name="fromMonth"
              rules="required"
              :options="monthsFrom"
              :label="isRight ? 'arabicName' : 'englishName'"
              @change="
                (v) => {
                  updateToMonth(v);
                }"
            />
          </b-form-group>
        </b-col>
        <!-- to year -->
        <b-col md="3">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.toYear"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="toYear"
              field="select"
              name="toYear"
              :options="years"
              label="arabicName"
              @change="(val) => {
                if (!checkToYearNotGreaterThanFromYear(val)) {
                  return;
                }
                selectedItem.toMonth = 0;
                redeclareMonth(true);
              }"
            />
          </b-form-group>
        </b-col>
        <!-- to month -->
        <b-col md="3">
          <b-form-group>
            <g-field
            :disabled="!selectedItem.employeeId"
              :value.sync="selectedItem.toMonth"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="toMonth"
              field="select"
              name="toMonth"
              rules="required"
              :options="monthsTo"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- value -->
        <b-col md="3">
          <g-field
            id="value"
            :value.sync="selectedItem.value"
            name="value"
            rules="required"
            label-text="value"
            @input="
              (v) => {
                validateData();
                caculateBenefitsAndDeduction(
                  v,
                  employeeObject,
                  benefitAndDeductionObject
                );
              }
            "
          />
        </b-col>

        <!-- total -->
        <b-col md="3">
          <g-field
            id="total"
            :value.sync="selectedItem.total"
            name="total"
            label-text="total"
          />
        </b-col>
      </b-row>

      <!-- Operations Section -->
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end mb-1"
        >
          <b-button
            v-permission="$route.meta.permission"
            type="submit"
            data-action-type="save"
            variant="gradient-primary"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            {{ $t("save") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>
<script>
import {
  salaryItemTypes,
  salaryItemNatures,
  months,
  years,
} from '@/libs/acl/Lookups';

export default {
  props: ['id'],

  data() {
    return {
      filedName: '',
      employees: [],
      salaryItems: [],
      salaryItemTypes: salaryItemTypes,
      salaryItemNatures: salaryItemNatures,
      selectedItem: {
        nature: '',
        total: 0,
        fromMonth: '',
        toMonth: '',
        employeeId: 0,
        fromYear: '',
        toYear: ''
      },
      employeeObject: {},
      benefitAndDeductionObject: {},
      monthsFrom: [],
      monthsTo: months,
      years: years,
      splityear: []
    };
  },
  computed: {},
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  watch: {
    'selectedItem.employeeId'(val) {
      if (!val) {
        this.monthsFrom = []
      }
    },

    'selectedItem.fromYear'(val) {
      if (!val) {
        this.monthsFrom = []
      }
    },
  },
  mounted() {
    this.getEmployee();
    this.getSalaryItem();

    // if all years selected
    if (!this.currentYear.year) {
      this.years = this.years.filter(year => this.fiscalYears.some(fiscal => fiscal.year === year.id))
    }

    // if specific year selected
    if (this.currentYear.year.toString().includes('/')) {
      var one = this.currentYear.year ? this.currentYear.year.split('/')[0] : '';
      this.splityear.push(one);
      var two = this.currentYear.year ? this.currentYear.year.split('/')[1] : '';
      this.splityear.push(two);
      this.years = this.years.filter(year => this.splityear.includes(year.id.toString()))
    } else {
      this.years = this.years.filter(year => this.fiscalYears.some(fiscal => fiscal.year === year.id))
    }

    if (this.id <= 0 || !this.id) {
    if (this.currentYear.year.toString().includes('/')) {
      this.selectedItem.fromYear = Number(this.currentYear.year.split('/')[0]);
     this.selectedItem.toYear = Number(this.currentYear.year.split('/')[0]);
    } else {
      this.selectedItem.fromYear = this.currentYear.year;
      this.selectedItem.toYear = this.currentYear.year;
    }
  }
    // get object in case edit
    if (this.id > 0) {
      this.getSelected();
    }
  },

  methods: {
    checkToYearNotGreaterThanFromYear(toYear) {
      if (Number(toYear.id) < Number(this.selectedItem.fromYear)) {
      this.doneAlert({
          type: 'error',
          text: this.$t('mustFromYearGreaterThanToYear'),
        });
        this.selectedItem.toYear = null;
        return false;
    }
    return true;
  },
    validateYear(date) {
      if (this.getDate(this.fiscalYearStart) > this.getDate(date) || this.getDate(date) > this.getDate(this.fiscalYearEnd)) {
      this.doneAlert({
          type: 'error',
          text: this.$t('youdonothaveaccesstothisyear'),
        });
        return false;
      }
      return true;
    },
    updateToMonth(item) {
      this.selectedItem.toMonth = item.id;
    },
    updateToYear(item) {
      this.selectedItem.toYear = item.id;
    },
    updateNature(item) {
      this.selectedItem.nature = item.nature;
    },
    // calcuate benefit and deduction for employee
    caculateBenefitsAndDeduction(item, employee, benefitAndDeductionObject) {
      if (benefitAndDeductionObject.nature === 'days') {
        this.selectedItem.total = (
          ((employee.basicSalary * 12) / 365) * item).toFixed(2);
      } else if (benefitAndDeductionObject.nature === 'value') {
        this.selectedItem.total = item;
      } else if (benefitAndDeductionObject.nature === 'hours') {
        this.selectedItem.total = (
          ((employee.basicSalary * 12) / 365 / 8) * item).toFixed(2);
      } else {
        this.selectedItem.total = ((employee.basicSalary / 100) * item).toFixed(
          2
        );
      }
    },
    // get object from server side
    getSelected() {
      this.get({ url: 'EmployeeBenefitsDeductions', id: this.id }).then(
        (data) => {
          this.selectedItem = data;
          this.employeeObject = this.employees.find(
            (val) => val.id === data.employeeId
          );
          this.benefitAndDeductionObject.nature = this.salaryItemNatures.find(
            (val) => val.id === data.nature
          ).id;
        }
      ).then(() => {
        this.redeclareMonth();
      });
    },
    // get all employees
    getEmployee() {
      this.get({ url: 'employees' }).then((data) => {
        this.employees = data.filter(x => !x.isSuspended);
      });
    },
    // get all salary item (benfits and deduction)
    getSalaryItem() {
      this.get({ url: 'salaryItems' }).then((data) => {
        this.salaryItems = data;
      });
    },
    validateData() {
      if (this.selectedItem.employeeId <= 0) {
        this.doneAlert({ text: this.$t('mustSelectEmployeeBeforeEnterValue'), type: 'warning', timer: 10000 });
      } else if (!this.selectedItem.itemType) {
        this.doneAlert({ text: this.$t('mustSelectTypeBeforeEnterValue'), type: 'warning', timer: 10000 });
      } else if (!this.selectedItem.nature) {
        this.doneAlert({ text: this.$t('mustSelectBenefitsOrDeductionBeforeEnterValue'), type: 'warning', timer: 10000 });
      } else if (this.selectedItem.nature === 'percentage' && this.selectedItem.value && (this.selectedItem.value > 100 || this.selectedItem.value <= 0)) {
        this.doneAlert({ text: this.$t('percentageValueMustbeBetween1And100'), type: 'warning', timer: 10000 });
      }
    },
    // save data to db
    save() {
      if (!this.validateYear(this.selectedItem.transactionDate)) return;
      // check value greater than zero
      if (this.selectedItem.value <= 0) {
        this.doneAlert({ text: this.$t('mustSelectRoom'), type: 'warning', timer: 10000 });
        return;
      }
      this.selectedItem.branchId = this.branchId;
      if (this.selectedItem.id > 0) {
        this.update({
          url: `EmployeeBenefitsDeductions/${false}`,
          data: this.selectedItem,
          id: this.selectedItem.id,
        }).then(() => {
          this.doneAlert({ text: this.$t('updatedSuccessfully') });
          this.$router.push({ name: 'benefitsAndDeductions' });
        });
      } else {
        this.create({
          url: `EmployeeBenefitsDeductions/${false}`,
          data: this.selectedItem
        }).then(() => {
          this.doneAlert({ text: this.$t('savedSuccessfully') });
          this.$router.push({ name: 'benefitsAndDeductions' });
        });
      }
    },
    redeclareMonth(toyear) {
       var fromMonth = new Date(this.fiscalYearStart).getMonth() + 1;
       var toMonth = new Date(this.fiscalYearEnd).getMonth() + 1;
      if (this.selectedItem.employeeId && this.selectedItem.fromYear) {
        this.get({ url: `EmployeeBenefitsDeductions/GetMonthsForCalculatedSalary?employeeId=${this.selectedItem.employeeId}&year=${this.selectedItem.fromYear}&toYear=${this.selectedItem.toYear}&fromMonth=${fromMonth}&toMonth=${toMonth}` }).then((data) => {
          if (toyear) {
            if (new Date(this.fiscalYearEnd).getFullYear() === this.selectedItem.toYear) {
            this.monthsTo = months.filter(mon => !data.includes(mon.id) && mon.id <= toMonth);
            } else {
              this.monthsTo = months.filter(mon => !data.includes(mon.id) && mon.id >= fromMonth);
            }
          } else {
          this.monthsFrom = months.filter(month => !data.includes(month.id))
         if (new Date(this.fiscalYearStart).getFullYear() === this.selectedItem.fromYear) {
          this.monthsFrom = this.monthsFrom.filter(mon => mon.id >= fromMonth);
          this.monthsTo = this.monthsFrom;
         } else if (new Date(this.fiscalYearEnd).getFullYear() === this.selectedItem.toYear) {
          this.monthsFrom = this.monthsFrom.filter(mon => mon.id < fromMonth);
          this.monthsTo = this.monthsFrom;
         }
        }
      })
      }
    }
  },
};
</script>
<style>
.header {
  display: flex;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #a074c7;
  border-radius: 5px;
}

.hide-code {
  display: none;
}

.header h4 {
  color: white;
}
</style>
